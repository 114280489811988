import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import { Hero } from "../components/hero"
import Breadcrumb from "../components/slices/Breadcrumb"
import { buildBreadcrumb } from "../utils/buildBreadcrumb"
import { FavouritePod } from "../components/pods"

// Query for the Blog Home content in Prismic
export const query = graphql`
  query LatestFavsQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            title {
              text
            }
          }
          id
          type
        }
      }
    }
    allPrismicLatestFavsListing {
      edges {
        node {
          data {
            title {
              text
            }
            hero_text_colour
            hero_image {
              url
            }
          }
          id
          uid
          type
        }
      }
    }
    allPrismicFavourit(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            image {
              url
            }
            link {
              __typename
              url
            }
          }
          first_publication_date
        }
      }
    }
  }
`

export default ({ data }) => {
  const {
    allPrismicHomepage,
    allPrismicLatestFavsListing,
    allPrismicFavourit: allPrismicFavourite,
  } = data
  const homeContent = allPrismicHomepage.edges[0].node
  const pageData = allPrismicLatestFavsListing.edges[0].node
  const latestFavs = allPrismicFavourite.edges
  const thisBreadcrumb = buildBreadcrumb([homeContent, pageData])

  return (
    <Layout>
      <Hero className="compact" props={pageData.data} />
      <div className="latest-favs">
        <div className="[ container -pinched ]">
          <Breadcrumb data={thisBreadcrumb} className="border-bottom" />
          <div className="latest-favs__listing latest-favs__grid">
            {latestFavs.map((latestFav, index) => {
              return <FavouritePod key={index} data={latestFav} />
            })}
          </div>
          {!latestFavs.length && (
            <h2 className="title -center">No favourites found</h2>
          )}
        </div>
      </div>
    </Layout>
  )
}
